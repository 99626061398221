<template>
  <div>
    <div class="subBan"><img src="../../../public/pc/images/banner-news.jpg"></div>
    <div class="subNav wid">
      <div class="subTypeName">Company news</div>
      <div class="subTypeName tt">公司新闻</div>
      <div class="clr"></div>
      <center>
        <dl></dl>
      </center>
      <div style="background:#c6c6c6;margin:20px 0 10px;width:100%;height:1px"></div>
    </div>
    <ul class="news wid height" id="clear">
      <li v-for="(item, key) in list" :key="key">
        <div class="newsLeft">
          <img :src="item.image" :alt="item.title">
        </div>
        <div class="newsRight">
          <div class="newsTit">
            <a href="javascript:;" @click="$router.push(`/news/detail/${item.id}`)" :title="item.title">{{
                item.title
              }}</a>
          </div>
          <div class="date">{{ item.date }}</div>
          <div class="summary">{{ item.summary }}</div>
        </div>
      </li>
      <!--      <div id="pages" class="text-c">
              <a class="a1">47条</a> <a href="" class="a1">上一页</a>
              <span>1</span>
              <a href="index-content_index_lists_62_2.html">2</a>
              <a href="index-content_index_lists_62_3.html">3</a>
              <a href="index-content_index_lists_62_4.html">4</a>
              <a href="index-content_index_lists_62_5.html">5</a>
              <a href="index-content_index_lists_62_6.html">6</a>
              <a href="index-content_index_lists_62_2.html" class="a1">下一页</a>
            </div>-->
    </ul>
    {{pageSize}}
    <el-pagination
      :page-size="5"
      v-model="pageSize"
      style="text-align: center; margin-bottom: 50px"
      background
      layout="total, prev, pager, next"
      @current-change="getNews"
      :total="total">
    </el-pagination>


  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSize:5,
      list: [],
      total: 0,
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    getList() {
      this.list = [
        {
          id: '1',
          image: '',
          title: '关于绍兴市中富新材料有限公司新型建材智能制造生产基地项目环评的公示',
          summary: '关于绍兴市中富新材料有限公司新型建材智能制造生产基地项目环评的公示',
          date: '2022-07-06',
        }
      ]
    },
    getNews(index) {
      this.list = []
      this.$api.getWenZhang({type_id: 32, data_length: this.pageSize, page: index}).then(res => {
        const news = res.data.data;
        news.forEach(item => {
          const data = {
            id: item.id,
            image: item.image.length > 0 ? item.image[0].url : '',
            title: item.name,
            summary: item.jian_jie,
            date: item.create_time,
          }
          this.list.push(data)
        })
        this.total = res.data.total
      })
    },
  },
}
</script>

<style lang="less" scoped>

</style>
